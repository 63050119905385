import { ProfileProvider, useAuth, useFlagMatch } from '@pm/core';
import MaintenancePage from '../pages/MaintenancePage';
import { lazy } from 'react';

const AuthAppLazy = lazy(() => import('./AuthApp'));
const InauthAppLazy = lazy(() => import('./InauthApp'));

const App = () => {
  const isMaintenanceEnabled = useFlagMatch({
    flag: 'maintenance',
    defaultValue: false,
  });
  const { isLoggedIn } = useAuth();

  if (isMaintenanceEnabled) {
    return <MaintenancePage />;
  }

  if (isLoggedIn) {
    return (
      <ProfileProvider>
        <AuthAppLazy />
      </ProfileProvider>
    );
  }

  return <InauthAppLazy />;
};

export default App;
