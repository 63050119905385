const Logo = () => (
  <a href="https://talkwithfrida.com" target="_blank" rel="noreferrer">
    <img
      className="mx-auto mt-6 w-1/3"
      src="/images/frida-logo.svg"
      alt="Frida"
    />
  </a>
);

export default Logo;
